export const policyText: string | TrustedHTML = `<h2>Политика обработки персональных данных Сайта https://clean-car.net   ООО «КЛИНКАР»</h2>

<ol>
  <li><h3>ИНФОРМАЦИЯ О ДОКУМЕНТЕ</h3>
    <ol>
      <li>Персональные данные (далее – «ПД») – это любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных). Персональными данными (ПД) являются как ФИО, дата рождения, адрес проживания, в т.ч. электронная почта, так и IP-адрес и др.</li>
      <li>Настоящий документ является Политикой обработки персональных данных (далее – «Политика»). Политика нужна для обеспечения конфиденциальности и защиты персональных данных от несанкционированного доступа и распространения.</li>
      <li>Политика размещена на сайте https://clean-car.net  (далее – «Сайт») и действует в отношении персональных данных физических лиц, использующих Сайт (далее – «Пользователи»).</li>
      <li>Когда Пользователь принимает условия Политики, он подтверждает, что ознакомился и согласен с Политикой и указанными в ней условиями сбора и обработки персональных данных. Если Пользователь не согласен с Политикой, он должен прекратить использовать Сайт.</li>
      <li>Отношения, связанные с обработкой персональных данных Пользователей, регулируются в соответствии с Политикой, Федеральным законом от 27 июля 2006 года № 152-ФЗ «О персональных данных» (далее – ФЗ «О персональных данных») и иными нормативными правовыми актами Российской Федерации в области персональных данных.</li>
      <li>Действующая редакция Политики всегда находится на странице по адресу: https://clean-car.net  .</li>
      <li>Помимо Политики, между Пользователем и Оператором также заключается Пользовательское соглашение, содержащее все необходимые и существенные условия предоставления доступа и использования сервиса (далее – «Пользовательское соглашение»). Пользовательское соглашение размещено по адресу: https://clean-car.net</li>
    </ol>
  </li>
  <li><h3>ОПЕРАТОР ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <ol>
      <li>Персональные данные обрабатывает Общество с ограниченной ответственностью «КЛИНКАР» (ИНН: 7702464454, КПП: 771501001, ОГРН: 1197746158752), адрес места нахождения: 127273, г. Москва, вн.тер.г. Муниципальный Округ Отрадное, проезд Нововладыкинский, д. 12А, этаж 3 ПОМ 5  (далее – «Оператор»). С нами можно связаться по следующим контактным данным:
        <ul>
          <li>Тел.: +7 499 130-95-95</li>
          <li>Адрес электронной почты: info@clean-car.net</li>
        </ul>
      </li>
      <li>Оператор оставляет за собой право изменять или дополнять Политику в любой момент. Оператор уведомляет Пользователя об изменениях Политики через электронную почту, указанную при регистрации. Новая редакция Политики вступает в силу с момента опубликования на Сайте, если иное не предусмотрено новой редакцией. Продолжение использования Сайта после внесения изменений означает согласие Пользователя с такими изменениями.</li>
      <li>Оператор вправе поручить обработку персональных данных сторонней организации. При этом, Оператор обязуется не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено законодательством Российской Федерации.</li>
    </ol>
  </li>
  <li><h3>ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <ol>
      <li>Оператор обрабатывает персональные данные путем совершения любых действий (операций) или совокупности действий (операций) с персональными данными. Обработка происходит как с использованием средств автоматизации, так и без их использования. Обработка персональных данных включает в себя, в том числе:
        <ul>
          <li>сбор, запись, систематизацию, накопление, хранение;
           <li>уточнение (обновление, изменение);</li>
           <li>извлечение, использование, обезличивание;</li>
           <li>блокирование, удаление, уничтожение.</li>
        </ul>
      </li>
      <li>Оператор осуществляет сбор персональных данных:
        <ul>
          <li>при регистрации Пользователя (создании учетной записи);</li>
          <li>при использовании Сайта и сервиса, в автоматическом режиме либо при внесении Пользователем по своей инициативе дополнительных Персональных данных при использовании Сайта.</li>
        </ul>
      </li>
      <li>Срок обработки и хранения персональных данных ограничен:<br/>
        (а)\tдостижением целей обработки и хранения персональных данных;<br/>
        (б)\tсроком действия согласия Пользователя на обработку персональных данных – в том числе включая согласие, данное на обработку персональных данных в целях информирования о новых продуктах и услугах, специальных предложениях и различных событиях, связанных с деятельностью Оператора.
      </li>

      <li>Оператор не планирует и не осуществляет трансграничную передачу персональных данных Пользователей. Уничтожение персональных данных</li>
      <li>При достижении целей обработки, Оператор прекращает обработку и обеспечивает уничтожение Персональных данных в срок, установленный:<br/>
      (а)\tлокальными актами Оператора;<br/>
      (б)\tсогласиями на обработку персональных данных.
      </li>
      <li>В случае отзыва Пользователем согласия на обработку персональных данных, Оператор прекращает обработку и обеспечивает уничтожение персональных данных.</li>
      <li>В случае отзыва Пользователем согласия на обработку персональных данных, Оператор прекращает обработку персональных данных, собранных в рекламных целях (продвижение товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи), немедленно.</li>
      <li>Уничтожение персональных данных подразумевает удаление персональных данных с серверов Оператора в случае автоматизированной обработки, и (или) уничтожение материальных носителей в случае неавтоматизированной обработки, в результате которых восстановление содержания персональных данных становится невозможным.</li>
    </ol>
<li><h3>ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ<br/></h3>
Оператор осуществляет обработку персональных данных в следующих целях:
  <ol>
    <li>заключения и исполнения Пользовательского соглашения, в том числе для использования Сайта в соответствии с его функциональным назначением, связи Оператора с Пользователем, включая направление уведомлений, запросов и информации, касающихся использования сервиса, Приложения и (или) Сайта;</li>
    <li>улучшения качества услуг и сервиса Оператора, Сайта, удобства его использования, разработки нового функционала, в том числе путем анализа результатов использования;</li>
    <li>информирования о новых продуктах и услугах, специальных предложениях и различных событиях, связанных с деятельностью Оператора;</li>
    <li>обработки запросов Пользователей о предоставлении доступа к персональным данным, обращений об уточнении или уничтожении Персональных данных, иных запросов и обращений, а также исполнения иных обязанностей Оператора.</li>
  </ol>
</li>
<li><h3>ОСНОВАНИЕ ДЛЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <ol>
      <li>Обработка персональных данных осуществляется с соблюдением принципов:
        (ст. 5 Закона «О персональных данных»)
        <ul>
          <li>законности и справедливости;</li>
          <li>ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей (а также недопущения обработки персональных данных, несовместимой с целями сбора персональных данных; недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой; обработки только тех персональных данных, которые отвечают целям их обработки);</li>
          <li>соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки (а также недопущения обработки персональных данных, избыточных по отношению к заявленным целям их обработки);</li>
          <li>обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;</li>
          <li>хранения персональных данных не дольше, чем этого требуют цели обработки (а также уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом).</li>
        </ul>
      </li>
<li>Оператор осуществляет обработку персональных данных на следующих законных основаниях:<br/>
    (а)\tдля целей заключения и исполнения Пользовательского соглашения, договоров между Оператором и Пользователем, включая улучшение качества услуг, сервиса и Сайта, удобства их использования, разработки нового функционала, анализа результатов их использования;(на основании п. 5 ч. 1 ст. 6 Закона «О персональных данных»)<br/>
    (б)\tдля проведения статистических и иных исследований на основе обезличенных персональных данных;(на основании п. 9 ч. 1 ст. 6 Закона «О персональных данных»)<br/>
    (в)\tдля целей информирования о новых продуктах и услугах, специальных предложениях и различных событиях, связанных с деятельностью Оператора – на основании согласия на обработку персональных данных;(на основании п. 1 ч. 1 ст. 6 Закона «О персональных данных»)<br/>
    (г)\tдля целей обработки запросов Пользователей о предоставлении доступа к персональным данным и обращений Пользователей об уточнении или уничтожении персональных данных и исполнения иных, предусмотренных законодательством обязанностей;<br/>

    (д)\tдля целей осуществления прав и законных интересов Оператора или третьих лиц.(на основании п. 7 ч. 1 ст. 6 Закона «О персональных данных»)<br/>
  </li>
    </ol>
</li>

<li><h3>ПЕРСОНАЛЬНЫЕ ДАННЫЕ ДЛЯ ЦЕЛЕЙ ОБРАБОТКИ</h3>
  <ol>
    <li>Для целей заключения и исполнения Пользовательского соглашения, Оператор обрабатывает следующие категории персональных данных:
      <ul>
        <li>имя, фамилия, отчество, дата рождения, адрес регистрации, почтовый адрес, паспортные данные, серии и номера иного документа, удостоверяющего личность, ИНН, банковские реквизиты, которые предоставляются Пользователем, адрес электронной почты, контактный телефон, номер СТС транспортного средства, государственный номер транспортного средства.</li>
        <li>данные об использовании Сайта:<br/>
          (а)\tсведения о совершенных с использованием Сайта денежных операциях (даты, места, наименование транзакции и т.д.);<br/>
          (б)\tинформация о действиях онлайн, просматриваемых материалах, в том числе о посещаемых страницах и используемых приложениях.
        </li>
        <li>данные, дополнительно предоставляемые Пользователями по запросу Оператора в целях исполнения обязательств перед Пользователями в отношении оказания услуг (функционирования Сайта и оказании услуг Оператором).</li>
      </ul>
    </li>
    <li>В целях улучшения качества услуг и сервиса Оператора, Сайта, удобства его использования, разработки нового функционала, в том числе путем анализа результатов использования, Оператор обрабатывает:
      <ul>
        <li>cookie-файлы, позволяющие отслеживать активность Пользователя в рамках Сайта. К cookie-файлам относят небольшие текстовые файлы, передаваемые в браузер веб-сайтами;</li>
        <li>обезличенные, технические и/или статистические данные об использовании Сайта в автоматическом режиме, в том числе:<br/>
(а)\tIP-адрес, технические характеристики мобильного устройства и программного обеспечения, используемых Пользователем;<br/>
(б)\tданные о местоположении (полученные на основе любых доступных данных, включая спутниковые, беспроводные сети, IP-адрес, A-GPS, любые иные методы позиционирования мобильного устройства и/или иная информация об определяемой посредством электронных сетей общего пользования местоположении);<br/>
(в)\tданные, полученные в результате доступа к камере и т.п. устройств.
        </li>
      </ul>
    </li>
<li>Указанная в п. 6.2 Политики информация позволяет в дальнейшем идентифицировать Пользователя или устройство, запомнить сеанс работы или сохранить некоторые настройки и предпочтения пользователя, специфичные для этих конкретных сайтов. Такая информация после сохранения в браузер и до истечения установленного срока действия или удаления с устройства будет отправляться при каждом последующем запросе на сайт, от имени которого они были сохранены, вместе с этим запросом для обработки на стороне Оператора.</li>
<li>Если Пользователь не согласен с обработкой cookie-файлов, он должен принять на себя риск, что в таком случае функции и возможности сайта могут не быть доступны в полном объеме, а затем следовать по одному из следующих вариантов:<br/>
(а)\tпроизвести самостоятельную настройку браузера и ограничить сбор и отправку cookie-файлов для Сайта;<br/>
(б)\tпереключиться в специальный режим «инкогнито» браузера для использования сайтом cookie-файлов до закрытия окна браузера или до переключения обратно в обычный режим;<br/>
(в)\tпокинуть Сайт во избежание дальнейшей обработки cookie-файлов.<br/>
</li>

<li>Для целей информирования Пользователей о новых продуктах и услугах, специальных предложениях и различных событиях, связанных с деятельностью Оператора, Оператор обрабатывает следующие категории Персональных данных:
  <ul>
    <li>адрес электронной почты;</li>
    <li>номер телефона Пользователя.</li>
  </ul>
</li>
<li>Оператор не осуществляет обработку:<br/>
(а)\tспециальных категорий персональных данных;<br/>
(касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья субъекта персональных данных)<br/>
(б)\tбиометрических персональных данных.<br/>
(касающихся физиологических и биологических особенностей человека, на основании которых можно установить его личность и которые используются Оператором для установления личности Пользователя)<br/>
</li>

<li>\tПользователь может в любой момент изменить параметры конфиденциальности своего устройства и отключить функцию, предоставляющую Сайту информацию о своем местонахождении. При этом отключение доступа к сведениям о местонахождении может затронуть определенные функции Сайта. По всем вопросам, связанным с параметрами конфиденциальности устройства Пользователя, ему необходимо обращаться за помощью к его производителю или оператору мобильной связи.</li>
<li>\tПользователь соглашается, что блокировка предоставления некоторых сведений может отразиться на функциональности Сайта. Требование о прекращении обработки указанных в настоящем разделе категорий Персональных данных приведет к расторжению Договора по инициативе Пользователя, в том числе в соответствии с условиями Пользовательского соглашения.</li>
</ol>
</li>
<li><h3>ПРАВА ПОЛЬЗОВАТЕЛЯ В ОТНОШЕНИИ ПЕРСОНАЛЬНЫХ ДАННЫХ<br/></h3>
  Отозвать согласие на обработку персональных данных
  <ol>
<li>\tСогласие на обработку персональных данных в рекламных целях (для целей информирования о новых продуктах и услугах, специальных предложениях и различных событиях, связанных с деятельностью Оператора) может быть отозвано Пользователем в любой момент.</li>
    <li>Пользователь может направить отзыв согласия Оператору по адресу электронной почты info@clean-car.net  с указанием в строке темы «Персональные данные». Пользователь также может отозвать согласие иным способом.</li>
    <li>Оператор при получении отзыва согласия на обработку персональных данных утрачивает право на продолжение обработки персональных данных Пользователя. Оператор утрачивает право обрабатывать персональные данные в объеме, указанном в согласии Пользователя.<br/>
    <li>Получить доступ к персональным данным</li>
    <li>Пользователь имеет право на получение информации, касающейся обработки его персональных данных, в том числе содержащей:<br/>
(а)\tподтверждение факта обработки персональных данных;<br/>
(б)\tправовые основания и цели обработки персональных данных;<br/>
(в)\tцели и применяемые Оператором способы обработки персональных данных.<br/>
    </li>
    <li>Для получения информации, касающейся обработки персональных данных Пользователю необходимо направить Оператору запрос о предоставлении доступа к Персональным данным.</li>
    <li>В запросе необходимо указать:<br/>
(а)\tсерию и номер документа, удостоверяющего личность Пользователя;<br/>
(б)\tсведения о дате выдачи и органе, выдавшем документ, удостоверяющий личность Пользователя;<br/>
(в)\tсведения, подтверждающие факт обработки персональных данных.
    </li>
 <li>\tЗапрос о предоставлении доступа к Персональным данным направляется по адресу электронной почты info@clean-car.net.</li>
 <li>\tОператор отвечает на запрос по адресу электронной почты, с которого поступил указанный запрос.
Уточнить или уничтожить персональные данные</li>
 <li>\tЕсли персональные данные являются:<br/>
(а)\tнеполными, устаревшими, неточными;<br/>
(б)\tнезаконно полученными;<br/>
(в)\tне являются необходимыми для заявленной цели обработки,<br/>

пользователи вправе:
   <ul>
    <li>самостоятельно изменять персональные данные о себе с помощью личного кабинета на Сайте (где это применимо);</li>
    <li>направить Оператору обращение об уточнении или уничтожении персональных данных.</li>
   </ul>
    <li>\tПри выявлении неправомерной обработки персональных данных, Оператор обязан прекратить неправомерную обработку и уничтожить неправомерно обрабатываемые Персональные данные.</li>
    <li>\tОбращения об уточнении или уничтожении персональных данных необходимо направлять по адресу электронной почты info@clean-car.net</li>
    <li>\tОператор отвечает на обращения по поводу уточнения или уничтожения персональных данных по адресу электронной почты, с которого поступило обращение.</li>
  </ol>
</li>
  <li><h3>ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <ol>
      <li>Оператор предпринимает организационные и технические меры, направленные на обеспечение безопасности персональных данных Пользователей, в том числе:
        <ul>

 <li>использует шифрование трафика для предотвращения незаконного доступа к персональным данным;</li>
 <li>изолирует сервера, на которых хранятся персональные данные, от прямого доступа из Интернета;</li>
 <li>назначает лицо, ответственное за обработку персональных данных;</li>
 <li>разрабатывает и принимает локальные акты (внутренние документы – приказы, положения, регламенты), устанавливающие процедуры, направленные на предотвращение незаконного доступа к персональным данным;</li>
 <li>осуществляет внутренний контроль за соответствием обработки персональных данных требованиям законодательства в области персональных данных, а также принятым Оператором локальным актам;</li>
 <li>проводит обучение и переподготовку сотрудников, участвующих в обработке персональных данных;</li>
 <li>проводит оценку эффективности принимаемых мер по обеспечению безопасности персональных данных;</li>
 <li>устанавливает правила доступа к персональным данным, обрабатываемым на сервере, а также обеспечивает учет действий, совершаемых с персональными данными.</li>
        </ul>
      </li>
      <li>Собираемые и обрабатываемые персональные данные расположены на серверах, находящихся на территории Российской Федерации. Это обеспечивает выполнение Оператором требований законодательства о локализации персональных данных граждан Российской Федерации на территории РФ.</li>
    </ol>
  </li>
</ol>`
export const confirmText: string | TrustedHTML = `<h2>Соглашение об обработке персональных данных</h2>
<p>Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте</p>
<p>https://clean-car.net (далее &ndash; Сайт), путём регистрации Пользователь:</p>
<ul>
<li>подтверждает, что все указанные им данные принадлежат лично ему,</li>
<li>подтверждает и признает, что им внимательно в полном объёме прочитано Соглашение и Политика обработки персональных данных, указываемых им в полях регистрации, текст соглашения и условия обработки персональных данных ему понятны и он согласен с ними;</li>
<li>даёт согласие на обработку Сайтом предоставляемых в составе информации персональных данных в целях заключения между ним и Сайтом настоящего Соглашения, а также его последующего исполнения;</li>
<li>выражает согласие с условиями обработки персональных данных</li>
</ul>
<p>Пользователь даёт своё согласие на обработку его персональных данных, а именно совершение действий, предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;, и подтверждает, что, давая такое согласие, он действует свободно, своей волей и в своём интересе.</p>
<p>Согласие Пользователя на обработку персональных данных является конкретным, информированным и сознательным.</p>
<p>Настоящее согласие Пользователя признается исполненным в простой письменной форме, на обработку следующих персональных данных: фамилия, имя, отчество (в том числе предыдущие фамилии, имена и (или) отчества, в случае их изменения); число, месяц, год рождения; пол; адрес и дата регистрации по месту жительства (месту пребывания), адрес фактического проживания; номер контактного телефона или сведения о других способах связи; идентификационный номер налогоплательщика; номер расчетного счета; наименование банка; БИК банка; ИНН банка; кор./счет банка; иные персональные данные, необходимые для достижения целей, предусмотренных законодательными и иными нормативными правовыми актами Российской Федерации.</p>
<p>Пользователь, предоставляет владельцу Сайта право осуществлять следующие действия (операции) с персональными данными: сбор и накопление; хранение в течение неограниченного количества времени; уточнение (обновление, изменение); использование; уничтожение; обезличивание; передача по требованию суда, в т.ч., третьим лицам, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа.</p>
<p>Указанное согласие действует бессрочно с момента предоставления данных и может быть отозвано Вами путём подачи заявления администрации Сайта с указанием данных, определённых ст. 14 Закона &laquo;О персональных данных&raquo;.</p>
<p>Отзыв согласия на обработку персональных данных может быть осуществлён путём направления Пользователем соответствующего распоряжения в простой письменной форме на адрес электронной почты (е-mail) <a href="mailto:info@clean-car.net">info@clean-car.net.</a></p>
<p>Сайт не несёт ответственности за использование (как правомерное, так и неправомерное) третьими лицами Информации, размещённой Пользователем на Сайте, включая её воспроизведение и распространение, осуществлённые всеми возможными способами. Сайт имеет право вносить изменения в настоящее Соглашение. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.</p>
<p>К настоящему Соглашению и отношениям между пользователем и Сайтом, возникающим в связи с применением Соглашения, подлежит применению право Российской Федерации.</p>`
export const userText:string | TrustedHTML = `<h2>Пользовательское соглашение<br/>
Сайта https://clean-car.net ООО &laquo;КЛИНКАР&raquo;</h2>
<ol>
    <li>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ<br/>В Пользовательском соглашении используемые термины имеют следующие значения:<ol>
            <li>Программа &ndash; программа для электронной вычислительной машины с наименованием &laquo;CleanCar&raquo;, доступная по адресу <a href="https://clean-car.net">https://clean-car.net</a> .</li>
            <li>Компания &ndash; Общество с ограниченной ответственностью &laquo;КЛИНКАР&raquo; (ИНН: 7702464454, КПП: 771501001, ОГРН: 1197746158752), адрес места нахождения: 127273, г. Москва, вн.тер.г. Муниципальный Округ Отрадное, проезд Нововладыкинский, д. 12А, этаж 3 ПОМ 5 , имеющее необходимые правомочия на предоставление Пользователю права использования Программы.</li>
            <li>Пользователь &ndash; собирательное понятие, одинаково обозначающее физических и юридических лиц, использующих Программу в соответствии с их функциональными возможностями.</li>
            <li>Правила использования Программы (также &laquo;Правила&raquo;, &laquo;Пользовательское соглашение&raquo;) &ndash; настоящий текст пользовательского соглашения, заключенного между Пользователем и Компанией, содержащий все необходимые и существенные условия предоставления Пользователю права использования Программы в соответствии с их функциональными возможностями. Пользователь обязуется соблюдать условия Правил при использовании им Программы.</li>
            <li>Интеллектуальная собственность &ndash; любые результаты интеллектуальной деятельности или средства индивидуализации, права на которые принадлежат Компании в соответствии с применимым законодательством и положениями Правил, включая Программу.</li>
            <li>Политика обработки персональных данных &ndash; публичный документ, определяющий политику Компании в отношении обработки персональных данных Пользователей, размещенный в сети Интернет по адресу: <a href="https://clean-car.net">https://clean-car.net</a> .</li>
            <li>Учетная запись &ndash; совокупность данных Пользователя, необходимая для его аутентификации при предоставлении удалённого доступа к Программе.</li>
            <li>Имя пользователя &ndash; уникальное имя, присваиваемое Пользователю во время Регистрации и используемое им при осуществлении авторизации для получения доступа к Программе.</li>
            <li>Пароль &ndash; набор символов, присваиваемый Пользователю при Регистрации, ввод которого требуется Пользователю для осуществления авторизации для доступа к Программе.</li>
            <li>Сайт &ndash; веб-сайт Компании, доступный в сети Интернет по адресу: https://clean-car.net</li>
        </ol>
    </li>
    <li>ОБЩИЕ ПОЛОЖЕНИЯ<ol>
            <li>Правила использования Программы являются публичной офертой в соответствии со статьей 437 Гражданского кодекса Российской Федерации (далее &ndash; &laquo;оферта&raquo;) и определяют условия, на которых Компания предоставляет Пользователю право использования Программы. Оферта адресована Пользователям и является официальным публичным предложением Компании заключить договор о предоставлении Пользователю права использования Программы в соответствии с ее назначением.</li>
            <li>Правила использования Программы регулируют правоотношения Пользователя и Компании с момента совершения Пользователем действий, предусмотренных офертой и означающих безоговорочное принятие им всех условий оферты без каких-либо изъятий или ограничений, на условиях присоединения.</li>
            <li>Правила регулируют права и обязанности Пользователя в части использования Программы по их назначению и не регулирует отношения, возникающие между Пользователями в случае, если такие отношения не связаны с использованием Программы по их функциональным возможностям. Если Пользователь не согласен принять все или некоторые условия Правил, он не вправе использовать Программу.</li>
            <li>Срок действия Пользовательского соглашения составляет 1 (один) год и начинается с момента регистрации Пользователя. Указанный срок автоматически продлевается на 1 (один) год при отсутствии возражений со стороны Пользователя и Компании.</li>
            <li>Регистрация Пользователя и принятие Правил путем проставления &laquo;галочки&raquo; в графе &laquo;Я принимаю условия Пользовательского соглашения&raquo; означает полное и безоговорочное согласие Пользователя с Правилами использования Программы. В случае несогласия с Правилами, Пользователь обязуется незамедлительно прекратить использование Программы.</li>
            <li>Действующая редакция Пользовательского соглашения постоянно размещена в сети Интернет по адресу: <a href="https://clean-car.net">https://clean-car.net</a> .</li>
            <li>Компания оставляет за собой право изменять или дополнять Правила в любой момент. О существенных изменениях Правил, в том числе касающихся возмездного использования Программы и (или) их отдельных функциональных возможностей, Компания информирует Пользователя посредством уведомления через электронную почту Пользователя, указанную при регистрации, или через Личный кабинет Пользователя, не позднее 30 (тридцати) календарных дней до даты вступления соответствующих изменений в силу.</li>
            <li>В случае несогласия Пользователя с новой редакцией Пользовательского соглашения, Пользователь вправе в одностороннем порядке отказаться от Договора путем направления письменного уведомления в течение 30 (тридцати) календарных дней с момента получения уведомления об изменении Правил. Если Пользователь не направил уведомление об отказе от Правил в указанный срок, Пользователь соглашается с изменениями, которые вступают в силу и составляют неотъемлемую часть правил по истечении указанного срока.</li>
            <li>Пользователь подтверждает, что до начала использования Программы он ознакомлен с Правилами и согласен с ними без каких-либо исключений и изъятий.</li>
        </ol>
    </li>
    <li>ПРЕДОСТАВЛЕНИЕ ДОСТУПА<ol>
            <li>Для получения доступа к Программе Пользователь осуществляет регистрацию. При регистрации Пользователь указывает следующие данные:<ul>
                    <ul>
                        <li>ФИО;</li>
                        <li>E-mail;</li>
                        <li>Телефон;</li>
                        <li>Дата рождения;</li>
                        <li>Номер СТС транспортного средства;</li>
                        <li>Государственный номер транспортного средства;</li>
                    </ul>
                    <li>Данные компании (для ИП и юр.лиц):<ul>
                            <li>Наименование компании (Название);</li>
                            <li>ИНН;</li>
                            <li>ОГРН;</li>
                            <li>Контактные данные (Телефон, эл. почта и т.д.);</li>
                            <li>Страна;</li>
                            <li>Город;</li>
                            <li>Адрес (включая индекс);</li>
                            <li>Юридический адрес (включая индекс);</li>
                            <li>Номер СТС транспортного средства;</li>
                            <li>Государственный номер транспортного средства;</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>Пользователь обязуется незамедлительно уведомлять Компанию о любом нарушении безопасности, связанном с доступом к Учетной записи, совершенном с использованием пароля Пользователя без его ведома и согласия.</li>
            <li>При регистрации и последующем внесении информации о своем профиле Пользователь должен указывать исключительно достоверную информацию.</li>
            <li>Доступ к Программе считается предоставленным Пользователю с момента создания Компанией Учетной записи. Акт предоставления неисключительной лицензии не составляется.</li>
            <li>Выполнение функций Программы возможно только при наличии доступа к сети Интернет. Пользователь самостоятельно получает и оплачивает такой доступ на условиях и по тарифам своего оператора связи или провайдера доступа к сети Интернет.</li>
            <li>Доступ к Программе может быть прекращен путем удаления Учетной записи Пользователя:<ol>
                    <li>Компанией при личном обращении Пользователя;</li>
                    <li>Компанией при выявлении нарушения Пользовательского соглашения.</li>
                </ol>
            </li>
            <li>Вопросы, возникающие у Пользователей, должны направляться Компании по адресу электронной почты: <a href="mailto:info@clean-car.net">info@clean-car.net</a></li>
        </ol>
    </li>
    <li>НАЗНАЧЕНИЯ ПРОГРАММЫ<ol>
            <li>Программа предназначена для оказания Компанией услуг Пользователям по мойке транспортных средств, шиномонтажу, выездному шиномонтажу, заправке топливом, перегонам транспортных средств. Вместе с тем, Правила распространяются на все виды услуг Компании, представленные в Программе, пока такие услуги с описанием присутствуют в Программе.</li>
            <li>Пользователь обязуется использовать Программу исключительно в целях, указанных в п. 4.1 Правил, не допуская запрещенного использования Программы в соответствии с разделом 7 Правил.</li>
        </ol>
    </li>
    <li>УСЛОВИЯ ОБ ОПЛАТЕ<ol>
            <li>Условия получения тех или иных услуг размещены в специальных разделах интерфейса Программы. Перед началом использования Программы Пользователь обязуется внимательно знакомиться с правилами и условиями получения соответствующей услуги. В случае несогласия с условиями получения соответствующей услуги и/или Правил Пользователь должен воздержаться от использования Программы. Пользователь согласен с тем, что Компания вправе в любое время расширить, ограничить, изменить перечень и стоимость услуг.</li>
            <li>Компания размещает на Сайте информацию об условиях и стоимости услуг, а также по запросу Пользователя направляет соответствующую информацию посредством электронной почты.</li>
            <li>Пользователь в целях использования функций и возможностей Программы осуществляет предоплату услуг или постоплату (оплату, осуществляемую после оказания услуг) по согласованию с Компанией.</li>
            <li>Неиспользование Пользователем Программы не влечет возврат денежных средств.</li>
            <li>Компания не запрашивает и не хранит на своих серверах данные банковских карт и прочую финансовую информацию Пользователя (за исключением банковских реквизитов в целях формирования договоров и выставления счетов), вся подобная информация обрабатывается напрямую партнерами Компании, имеющими с ним соответствующие соглашения и лицензии на осуществление платежных операций.</li>
        </ol>
    </li>
    <li>ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ<ol>
            <li>Все права на Интеллектуальную собственность, в том числе на Программу принадлежат Компании в соответствии с применимым законодательством и условиями Пользовательского соглашения.</li>
            <li>Все атрибуты и составные элементы Программы являются Интеллектуальной собственностью Компании и защищены в соответствии с законодательством Российской Федерации. Пользователь не вправе использовать такие атрибуты и составные элементы отдельно от Программы или иным способом, нарушающим Правила и применимое законодательство.</li>
            <li>Компания предоставляет Пользователю неисключительную, не подлежащую передаче и сублицензированию, ограниченную лицензию на использование Программы. Право использования Программы, предоставляемые согласно Правилам, обусловлено соблюдением Пользователем Правил. Пользователь настоящим признаёт, что при предоставлении удалённого доступа не происходит передача или уступка прав на Интеллектуальную собственность, и принятие Правил нельзя толковать как продажу и (или) уступку каких-либо прав на Интеллектуальную собственность.</li>
            <li>Компания сохраняет за собой исключительное право на Программу и Интеллектуальную собственность Компании, включая, но не ограничиваясь, право на объекты авторских и смежных прав (в том числе программный код, графические изображения и т.д.), на товарные знаки, знаки обслуживания, ноу-хау, фирменные наименования, коммерческие обозначения, на объекты патентных прав (изобретения, полезные модели и промышленные образцы), аудиовизуальные и звуковые эффекты, оформление, сюжеты, музыкальные произведения как зарегистрированные, так и незарегистрированные, а также все приложения, дополнения и модификации к ним. Если иное прямо не разрешено Правилами и соответствующим законодательством, Пользователю запрещается копировать, воспроизводить или распространять Интеллектуальную собственность и (или) Программу каким-либо образом на любых носителях, полностью или частично, без предварительного письменного согласия Компании. Все права, не предоставленные Пользователю Правилами в явно выраженной форме, сохраняются за Компанией.</li>
            <li>При использовании Программы запрещена загрузка, передача между Пользователями, изменение или размещение информации, данных или изображений, которые нарушают авторские и (или) смежные права третьих лиц на любые результаты интеллектуальной деятельности и средства индивидуализации, являющиеся объектами интеллектуальной собственности третьих лиц. Настоящим Пользователь признаёт, что несет ответственность в случае нарушения авторских и (или) смежных прав третьих лиц в соответствии с применимым законодательством.</li>
        </ol>
    </li>
    <li>ЗАПРЕЩЕННОЕ ИСПОЛЬЗОВАНИЕ<br>Пользователю запрещается:<ol>
            <li>Выдавать себя за другого человека или представителя организации и (или) сообщества, вводить Компанию в заблуждение, размещать информацию ложного характера;</li>
            <li>Любым способом препятствовать работе Программы, в том числе создавать, распространять или использовать программное обеспечение либо компьютерную информацию, предназначенную для несанкционированного уничтожения, блокирования, модификации, копирования компьютерной информации или нейтрализации средств защиты компьютерной информации (компьютерные вирусы), для осуществления несанкционированного доступа к Программе;</li>
            <li>Осуществлять попытки проверить уязвимость систем безопасности Программы.</li>
        </ol>
    </li>
    <li>ГАРАНТИИ И ЗАВЕРЕНИЯ<ol>
            <li>Компания предоставляет Пользователю Программу &laquo;как есть&raquo; и не гарантирует отсутствие в них ошибок, совместимости с какими-либо платформами, иным программным или аппаратным обеспечением Пользователя.</li>
            <li>Гарантии, указанные в пункте 8.1 Правил, являются существенным условием, которым обусловлен интерес Компании для заключения Пользовательского соглашения и предоставления удалённого доступа к Программе. Ни при каких обстоятельствах письменные или устные заявления Компания не должны трактоваться в противоречие с указанным положением.</li>
            <li>Компания гарантирует, что:<br><br>
                <ol>
                    <li>на дату подписания Договора Компания является единственным обладателем исключительных прав на Программу;</li>
                    <li>компании ничего не известно о правах третьих лиц, которые могли быть нарушены созданием Программы и предоставлением права использования Программы;</li>
                    <li>программа не является предметом судебного, административного или иного спора, залога или иного обременения;</li>
                    <li>компания обрабатывает Персональные данные согласно Политике обработки персональных данных, соответствующей положениям законодательства Российской Федерации в области персональных данных;</li>
                    <li>пользователь гарантирует, что обладает правоспособностью, дееспособностью, а также необходимыми полномочиями для принятия на себя, исполнения и соблюдения Правил, и Правила являются действительными и обязательными для исполнения Пользователем;</li>
                </ol>
            </li>
        </ol>
    </li>
    <li>ОГРАНИЧЕНИЯ ОТВЕТСТВЕННОСТИ<ol>
            <li>Компания прилагает все возможные усилия для обеспечения нормальной работоспособности Программы, однако не несет ответственности за какие-либо убытки, включая упущенную выгоду или реальный ущерб Пользователя, возникшие в связи с невозможностью использования Программы по причинам, зависящим от Пользователя или третьих лиц, а также за какие-либо убытки, включая упущенную выгоду или реальный ущерб, нанесенный Пользователем третьим лицам с использованием Программы, возникшие в том числе в результате:<ol>
                    <li>сбоев в работе Программы по причинам плановых или внеплановых технических и профилактических работ; сбоев в работе интернет-провайдеров, компьютерных сетей, серверов и средств;</li>
                    <li>неправомерных действий пользователей сети Интернет, направленных на нарушение информационной безопасности или нормального функционирования Программы;</li>
                    <li>проведения государственными и муниципальными органами, а также иными организациями мероприятий в рамках оперативно-розыскных мероприятий;</li>
                    <li>установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и (или) установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Правил.</li>
                </ol>
            </li>
            <li>Компания не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Сайте.</li>
            <li>Ограничение ответственности Компании, предусмотренное Правилами, должно применяться в максимальной мере, разрешенной законом. Ограничение ответственности Компании не применяется исключительно в тех случаях, когда какое-либо конкретное положение ограничения ответственности прямо запрещено императивными требованиями применимого законодательства.</li>
        </ol>
    </li>
    <li>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ<ol>
            <li>Пользовательское соглашение заменяет собой все предыдущие договоренности и соглашения между Пользователем и Компанией, касающиеся порядка использования Программы. Правила определяют все договоренности между Пользователем и Компанией относительно порядка использования Программы.</li>
            <li>В случае, если одно или несколько положений Пользовательского соглашения будут признаны незаконными и (или) недействительными, такие положения будут считаться изъятыми из Правил, при этом все другие положения остаются в силе и действуют в полном объеме. Компания обязуется изменить положения Правил, признанные незаконными и (или) недействительным таким образом, чтобы в максимальной степени отразить волю, законные интересы, а также намерения Пользователя и Компании.</li>
            <li>Пользователь не вправе уступать или переводить свои права или обязанности, предусмотренные Пользовательским соглашением или какую-либо их часть без получения предварительного письменного согласия на такую уступку от Компании.</li>
            <li>Компания имеет право предпринимать меры, необходимые для защиты Правил и своих интересов вне зависимости от срока давности этих нарушений. Таким образом, бездействие со стороны Компании при нарушении Пользователями Правил не лишает Компанию права предпринять необходимые для защиты своих интересов действия позднее и не означает отказ Компании от своих прав при совершении Пользователем подобных нарушений в будущем.</li>
            <li>Споры между Компанией и Пользователем разрешаются путем переговоров, а в случае невозможности такого урегулирования в судебном порядке - по месту нахождения Компании.</li>
            <li>К отношениям между Компанией и Пользователем, возникающим в связи с принятием и исполнением Пользовательского соглашения, применяется законодательство Российской Федерации.</li>
        </ol>
    </li>
</ol>`
